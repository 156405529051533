console.log('index.js loaded...')

import { auth, db, doSignIn, doSignUp } from "./account";
import $, { css } from "jquery";
require('bootstrap');
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from './images/logo.png';
import './index.css';
import { Timestamp, addDoc, collection, deleteDoc, doc, getDoc, getDocs, increment, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import Split from 'split.js'
import './split.js.css'
import 'jquery.qrcode'
import 'iconify-icon';
import { drawTrip, initMap } from "./map";
import dragula from 'dragula'
import 'dragula/dist/dragula.css'

var projects = new Map();
var interval2D=null;
var theDisplay;

const QRPREFIX = "https://edx.brand3d.com/?project=";


//Landing page
$('body').append('<div id="landing-page" class="beforelogin"></div>');{
    $('#landing-page').append('<h3>Brand3D Admin</h3>')
    $('#landing-page').append('<img id="landing-page-logo" src="' + Logo + '">')

    $('#landing-page').append('<div id="account-buttons" class="landing-page-button-panel"></div>');{

        $('#account-buttons').append('<button id="signup-button" type="button" class="btn btn-primary">Sign Up...</button>');
        $('#signup-button').on('click', function(){
            doSignUp()
        })

        $('#account-buttons').append('<button id="signin-button" type="button" class="btn btn-primary">Sign In...</button>');
        $('#signin-button').on('click', function(){
            doSignIn();
        })    

    }

    $('#landing-page').append('<div id="landing-page-version">' + BUILD_INFO.time + '</div>')
}

$('body').append('<div id="admin-page" class="afterlogin hidden"></div>');{

    $('#admin-page').append('<div id="projects-view" class="split"></div>')
    $('#admin-page').append('<div id="project-view" class="split"></div>')

    let size1 = localStorage.getItem('split1')
    if (size1) {
        size1 = JSON.parse(size1)
    } else {
        size1 = [20, 80] // default sizes
    }

    Split(['#projects-view', '#project-view'],{
        direction: 'horizontal',
        sizes: size1,
        gutterSize: 10,
        minSize: [100, 100],
        onDragEnd: function (size) {
            localStorage.setItem('split1', JSON.stringify(size))
        },
    })

    $('#projects-view').append('<div id="projects-view-list"></div>')
    $('#projects-view').append('<button id="add-project-button" type="button" class="btn btn-primary qr-control"><iconify-icon icon="material-symbols:add"></iconify-icon></button>');
    $('#add-project-button').on('click', function(){
        addDoc(collection(db,'projects'),{
            name: 'New Project',
            '2d': '',
            '3d': '',
            logo: Logo,
            display: [],
        })
        .catch(error => {
            console.error(error)
            alert('Could not add new project')
        })
    })

    $('#project-view').append('<div id="upper-view" class=""></div>')
    $('#project-view').append('<div id="lower-view" class=""></div>')


    let size2 = localStorage.getItem('split2')
    if (size2) {
        size2 = JSON.parse(size2)
    } else {
        size2 = [50, 50] // default sizes
    }

    Split(['#upper-view', '#lower-view'],{
        direction: 'vertical',
        sizes: size2,
        gutterSize: 10,
        minSize: [100, 100],
        onDragEnd: function (size) {
            localStorage.setItem('split2', JSON.stringify(size))
        },
    })

    $('#upper-view').append('<div id="info-view" class="split"></div>')
    $('#upper-view').append('<div id="map-view" class="split"></div>')

    let size3 = localStorage.getItem('split3')
    if (size3) {
        size3 = JSON.parse(size3)
    } else {
        size3 = [50, 50] // default sizes
    }

    Split(['#info-view', '#map-view'],{
        direction: 'horizontal',
        sizes: size3,
        gutterSize: 10,
        minSize: [100, 100],
        onDragEnd: function (size) {
            localStorage.setItem('split3', JSON.stringify(size))
        },
    })

    $('#info-view').append('<div id="info-view-container"></div>');{
        $('#info-view-container').append('<form id="info-view-name" class="form-floating"></form>');{
            $('#info-view-name').append('<input type="text" class="form-control" id="info-view-name-value" placeholder="Name of the project" value="">')
            $('#info-view-name').append('<label for="info-view-name-value">Name</label>')

            $('#info-view-name-value').on('change', function(){
                const id = $('#info-view-container').prop('projectid');
                updateDoc(doc(db,'projects',id),'name', $(this).val())
                .catch(error => {
                    console.error(error)
                    alert('Could not change name of project')
                })
            })
        }    

        $('#info-view-container').append('<form id="info-view-logo" class="form-floating"></form>');{
            $('#info-view-logo').append('<input type="text" class="form-control" id="info-view-logo-value" placeholder="https://.....image.png" value="">')
            $('#info-view-logo').append('<label for="info-view-logo-value">Logo</label>')

            $('#info-view-logo-value').on('change', function(){
                const id = $('#info-view-container').prop('projectid');
                updateDoc(doc(db,'projects',id),'logo', $(this).val())
                .catch(error => {
                    console.error(error)
                    alert('Could not change logo of project')
                })
            })
        }    

        $('#info-view-container').append('<form id="info-view-2d" class="form-floating"></form>');{
            $('#info-view-2d').append('<input type="text" class="form-control" id="info-view-2d-value" placeholder="https://.....image.png" value="">')
            $('#info-view-2d').append('<label for="info-view-2d-value">2D puzzle picture</label>')

            $('#info-view-2d-value').on('change', function(){
                const id = $('#info-view-container').prop('projectid');
                updateDoc(doc(db,'projects',id),'2d', $(this).val())
                .catch(error => {
                    console.error(error)
                    alert('Could not change logo of project')
                })
            })
        }    

        $('#info-view-container').append('<form id="info-view-display" class="form-floating"></form>');{
            $('#info-view-display').append('<div id="info-view-display-container" class="form-control"></div>');{
            }
            $('#info-view-display').append('<label for="info-view-3d-value">2D puzzle display order</label>')
        }

        $('#info-view-container').append('<form id="info-view-3d" class="form-floating"></form>');{
            $('#info-view-3d').append('<input type="text" class="form-control" id="info-view-3d-value" placeholder="https://p3d.in/e/.....+controls-hidden+load+spin" value="">')
            $('#info-view-3d').append('<label for="info-view-3d-value">3D puzzle picture</label>')

            $('#info-view-3d-value').on('change', function(){
                const id = $('#info-view-container').prop('projectid');
                updateDoc(doc(db,'projects',id),'3d', $(this).val())
                .catch(error => {
                    console.error(error)
                    alert('Could not change logo of project')
                })
            })
        }    

        $('#info-view-container').append('<div id="info-view-delete"></div>');{

            $('#info-view-delete').append('<div id="info-view-delete-group" class="input-group"></div>');{
                $('#info-view-delete-group').append('<div id="flex-grow" class="flex-grow-1 form-floating"></div>');{
                    $('#flex-grow').append('<input id="info-view-delete-value" type="text" class="rounded-0 rounded-start form-control">')
                    $('#flex-grow').append('<label>Type DELETE and hit the button to delete the project</label>')
                }
                //$('#info-view-delete-group').append('<span class="input-group-text"><i class="fas fa-times"></i></span>')
                $('#info-view-delete-group').append('<button class="btn btn-danger" type="button" disabled id="delete-project-button"><iconify-icon icon="fluent:delete-20-regular"></iconify-icon></button>')
            }

            $('#info-view-delete-value').on('keyup', function(){
                const value = $(this).val() != "DELETE"
                $('#delete-project-button').attr('disabled',value)
            })

            $('#delete-project-button').on('click', function(){
                const id = $('#info-view-container').prop('projectid');
                deleteDoc(doc(db,'projects',id))
                .catch(error => {
                    console.error(error);
                    alert('Could not delete project')
                })
            })
        }    
    }
    
    //$('#map-view').append('<div id="map-view-container"></div>')
    initMap('map-view')
  

    $('#lower-view').append('<div id="d2-view" class="split"></div>')
    $('#lower-view').append('<div id="d3-view" class="split"></div>')
    $('#lower-view').append('<div id="qr-view" class="split"></div>')

    let size4 = localStorage.getItem('split4')
    if (size4) {
        size4 = JSON.parse(size4)
    } else {
        size4 = [33, 33, 34] // default sizes
    }

    Split(['#d2-view', '#d3-view', '#qr-view'],{
        direction: 'horizontal',
        sizes: size4,
        gutterSize: 10,
        onDragEnd: function (size) {
            localStorage.setItem('split4', JSON.stringify(size))
        },
    })

    $('#d2-view').append('<img data-image="0" class="jigsawimage even" src="">')
    $('#d2-view').append('<img data-image="1" class="jigsawimage odd" src="">')
    $('#d2-view').append('<img data-image="2" class="jigsawimage even" src="">')
    $('#d2-view').append('<img data-image="3" class="jigsawimage odd" src="">')
    $('#d2-view').append('<img data-image="4" class="jigsawimage even" src="">')
    $('#d2-view').append('<img data-image="5" class="jigsawimage odd" src="">')
    $('#d2-view').append('<img data-image="6" class="jigsawimage even" src="">')
    $('#d2-view').append('<img data-image="7" class="jigsawimage odd" src="">')
    $('#d2-view').append('<img data-image="8" class="jigsawimage even" src="">')
    $('#d2-view').append('<img data-image="9" class="jigsawimage odd" src="">')
    $('#d2-view').append('<img data-image="10" style="opacity:1" class="jigsawimage" src="">')

    function getContainedSize(img) {
        var ratio = img.naturalWidth/img.naturalHeight
        var width = img.height*ratio
        var height = img.height
        if (width > img.width) {
          width = img.width
          height = img.width/ratio
        }
    
        return {
            width: width,
            height: height,
            x: (img.width - width)/2,
            y: (img.height - height)/2,
        }
    }

    function calculateClipPath(elem){
        const idx = elem.attr("data-image");
        if (idx > 9) return;
        const size = getContainedSize(elem[0]);

        const width = size.width/2
        const height = size.height/5

        
        let x,y;


        const TOPFULL = ' l ' + width + ',0 ';
        const RIGHTFULL = ' l 0,' + height + ' '
        const BOTTOMFULL = ' l -' + width + ',0 '
        const LEFTFULL = ' l 0,-' + height + ' '
        
        let pattern = [35, 15, 37, 5, 3, -5, 1, -10, -18, -15, 12, -15, 30, 0, 12, 15, -2, 5, 1, 10, 2, 10, 37, -5]

        function jigsawline(dx,dy,dz){
    
            let svgstr = "";
            for (let seg=0; seg < 6; seg++){
                let svgseg = 'c 0 0, ';
                
                svgseg += (width * pattern[seg*4 + (0 + dz)%2] * dx)/100 + ' ';
                svgseg += (height * pattern[seg*4 + (1 + dz)%2] * dy)/100 + ', ';
    
                svgseg += (width * pattern[seg*4 + 2 + (0 + dz)%2] * dx)/100 + ' ';
                svgseg += (height * pattern[seg*4 + 2 + (1 + dz)%2] * dy)/100 + ' ';
    
                svgstr += svgseg;
            }
    
            return ' ' + svgstr + ' ';
        }
    
        function topmountain(){
            return jigsawline(1,1,0)
        }
        function topvalley(){
            return jigsawline(1,-1,0)
        }
        function rightmountain(){
            return jigsawline(-1,1,1)
        }
        function rightvalley(){
            return jigsawline(1,1,1)
        }
        function bottommountain(){
            return jigsawline(-1,-1,0)
        }
        function bottomvalley(){
            return jigsawline(-1,1,0)
        }
        function leftmountain(){
            return jigsawline(1,-1,1)
        }
        function leftvalley(){
            return jigsawline(-1,-1,1)
        }
    
        let path;

        x=size.x+0;y=size.y+0;
        if (idx == 0) path = 'M ' + x + ',' + y + ' ' + TOPFULL +  rightmountain() + bottomvalley() + LEFTFULL; 

        x=size.x+width;y=size.y+0;
        if (idx == 1) path = 'M ' + x + ',' + y + ' ' + TOPFULL + RIGHTFULL + bottommountain() + leftvalley()
        
        x=size.x+0;y=size.y+height;
        if (idx == 2) path = 'M ' + x + ',' + y + topmountain() + rightvalley() + bottommountain() + LEFTFULL

        x=size.x+width;y=size.y+height;
        if (idx == 3) path = 'M ' + x + ',' + y + topvalley() + RIGHTFULL + bottomvalley() + leftmountain()

        x=size.x+0;y=size.y+2*height;
        if (idx == 4) path = 'M ' + x + ',' + y + topvalley() + rightmountain() + bottomvalley() + LEFTFULL;

        x=size.x+width;y=size.y+2*height;
        if (idx == 5) path = 'M ' + x + ',' + y + topmountain() + RIGHTFULL + bottommountain() + leftvalley()

        x=size.x+0;y=size.y+3*height;
        if (idx == 6) path = 'M ' + x + ',' + y + topmountain() + rightvalley() + bottommountain() + LEFTFULL

        x=size.x+width;y=size.y+3*height;
        if (idx == 7) path = 'M ' + x + ',' + y + topvalley() + RIGHTFULL + bottomvalley() + leftmountain()

        x=size.x+0;y=size.y+4*height;
        if (idx == 8) path = 'M ' + x + ',' + y + topvalley() + rightmountain() + BOTTOMFULL + LEFTFULL

        x=size.x+width;y=size.y+4*height;
        if (idx == 9) path = 'M ' + x + ',' + y + topmountain() + RIGHTFULL + BOTTOMFULL + leftvalley()

        elem.css('clip-path','path("' + path + ' Z")')
    }

    $('.jigsawimage').on('load', function(){
        calculateClipPath($(this));
    })

    const resizeObserver = new ResizeObserver((entries) => {
        calculateClipPath($('.jigsawimage[data-image="0"]'))
        calculateClipPath($('.jigsawimage[data-image="1"]'))
        calculateClipPath($('.jigsawimage[data-image="2"]'))
        calculateClipPath($('.jigsawimage[data-image="3"]'))
        calculateClipPath($('.jigsawimage[data-image="4"]'))
        calculateClipPath($('.jigsawimage[data-image="5"]'))
        calculateClipPath($('.jigsawimage[data-image="6"]'))
        calculateClipPath($('.jigsawimage[data-image="7"]'))
        calculateClipPath($('.jigsawimage[data-image="8"]'))
        calculateClipPath($('.jigsawimage[data-image="9"]'))
    });
    resizeObserver.observe(document.getElementById('d2-view')); 

    $('#d3-view').append('<iframe id="d3-view-image" title="3D Presentation" width="100%" height="100%" src=""></iframe>')
    $('#qr-view').append('<div id="qrcode-container"></div>');{
        $('#qrcode-container').append('<div id="qrcode" class="scaleddown"></div>')
        $('#qrcode-container').append('<a href="" download="" id="qrcode-download" type="button" class="btn btn-primary qr-control"><iconify-icon icon="material-symbols:download"></iconify-icon></a>')
        $('#qrcode-container').append('<button id="qrcode-print" type="button" class="btn btn-primary qr-control"><iconify-icon icon="material-symbols:print-outline"></iconify-icon></button>')

        $('#qrcode-print').on('click', function(){
            const iframe = document.createElement('iframe');

            // Make it hidden
            iframe.style.height = '100%';
            iframe.style.visibility = 'hidden';
            iframe.style.width = '100%';

            // Set the iframe's source
            iframe.setAttribute('srcdoc', '<html><body></body></html>');

            document.body.appendChild(iframe);

            iframe.addEventListener('load', function () {


                const page = document.createElement('div');
                page.style.display = 'flex';
                page.style.flexDirection = 'column'
                page.style.justifyContent = 'center'
                page.style.width = '100%';
                page.style.height = '100%';



                // Clone the image
                const image = document.createElement('img');
                image.src = $('#qrcode').children()[0].toDataURL('image/jpeg')
                image.style.objectFit = 'contain';

                page.appendChild(image)
            
                // Append the page to the iframe's body
                iframe.contentDocument.body.appendChild(page);
                
                image.addEventListener('load', function() {
                    // Invoke the print when the image is ready
                    iframe.contentWindow.print();
                });
            });

            iframe.contentWindow.addEventListener('afterprint', function () {
                iframe.parentNode.removeChild(iframe);
            });
        })

        $('#qrcode').on('click', function(){
            window.open(QRPREFIX + $(this).prop('projectid'), '_blank')
        })
    }
}

var projectsubscription = null;
function goOnline(){
    projectsubscription = onSnapshot(collection(db, 'projects'), snapshot => doProjects(snapshot))
}
function goOffline(){
    if (projectsubscription != null) projectsubscription();
    projectsubscription = null;
}

function init(){
    projects.clear();
    $('#projects-view-list').empty();
    emptyproject();
}

function resetJigSaw(){
    $('.jigsawimage[data-image="10"]').removeClass('hidden')
    for (let i=0; i <10; i++){
        const elem = $('.jigsawimage[data-image="' + i + '"]')
        elem.css('transition', '')
        elem.css('opacity', 0)
    }
    $('.odd').css('left','500px')
    $('.even').css('left','-500px')

}

function makeJigSaw(){
    $('.jigsawimage[data-image="10"]').addClass('hidden')

    for (let i=0; i <10; i++){
        let idx = i;
        if (theDisplay.length == 10) idx = theDisplay[i];

        const elem = $('.jigsawimage[data-image="' + idx + '"]')

        setTimeout(function(){
            elem.css('transition', 'opacity 2s ease-in, left 1s ease-in')
            elem.css('opacity', '1');
            elem.css('left', '0');
        }, i * 2000)
    }

    setTimeout(resetJigSaw,20000)
}

function emptyproject(){
    $('#info-view-container').addClass('hidden');
    $('.jigsawimage').addClass('hidden');
    $('#d3-view-image').addClass('hidden');
    $('#qrcode-container').addClass('hidden');
    drawTrip()
    if (interval2D) clearInterval(interval2D);
    interval2D=null;
    resetJigSaw();
}
function start(){  
    init()
    const profile = doc(db,'users',auth.currentUser.uid);
    
    let docData = {
        lastlogin                 :   Timestamp.fromDate(new Date()),
        created                   :   new Date(auth.currentUser.metadata.creationTime),
        personal: {
            displayname           :   auth.currentUser.displayName,
            email                 :   auth.currentUser.email,
            photourl              :   auth.currentUser.photoURL,
            phone                 :   auth.currentUser.phoneNumber,
            emailverified         :   auth.currentUser.emailVerified,
            anonymous             :   auth.currentUser.isAnonymous,    
        },
        tracking:{
            visits                :   increment(1),
            versions              :   BUILD_INFO.time,
        },
    }

    setDoc(doc(db,'users',auth.currentUser.uid),docData,{merge:true})
    .then(() => {
        return getDoc(profile,docData)
    })
    .then(snapshot => { 
        if (!snapshot.exists()) throw('user does not exists')
        const data = snapshot.data();
        if (!data.isAdmin == true) throw('user not admin')
        $('.beforelogin').addClass('hidden');
        $('.afterlogin').removeClass('hidden');       
        goOnline();
    })
    .catch((error) => {
        console.error("Error starting " + error)
        auth.signOut();
    })    
}

onAuthStateChanged(auth, async user => {
    if (user) {
        start();
    } else {
        goOffline();

        $('.beforelogin').removeClass('hidden');
        $('.afterlogin').addClass('hidden');
        $('.modal').modal('hide');
    }
});

function doProjects(snapshot){
    snapshot.docChanges().forEach(change => {

        const project = change.doc.data();
        const id = change.doc.id;

        if (change.type === "added") {
            addProject(id,project)
        }
        else if (change.type === "modified") {
            modifyProject(id,project);
        }
        else if (change.type === "removed") {
            removeProject(id)
        }
        else console.error('unhandled projects change: ' + change.type)
    })
}

function showProject(id){
    emptyproject();
    const item = projects.get(id);

    $('#info-view-name-value').val(item.name)
    $('#info-view-logo-value').val(item.logo)
    $('#info-view-2d-value').val(item['2d'])
    $('#info-view-3d-value').val(item['3d'])
    $('#info-view-container').removeClass('hidden');
    $('#info-view-container').prop('projectid', id)
    $('#info-view-delete-value').val('')

    $('#info-view-display-container').empty();
    for (let i=0; i < 10; i++){
        let jigsaw = i;
        if (item.display.length == 10) jigsaw = item.display[i]
        $('#info-view-display-container').append('<div id="info-view-display-container-jigsaw-container' + i + '" class="info-view-display-container-jigsaw-container dragable" data-jigsaw="' + jigsaw + '"></div>');{
            $('#info-view-display-container-jigsaw-container' + i).append('<div class="jigsaw-elem jigssaw-icon"><iconify-icon icon="mdi:jigsaw-outline"></iconify-icon></div>')
            $('#info-view-display-container-jigsaw-container' + i).append('<div class="jigsaw-elem jigssaw-text">' + jigsaw + '</div>')
        }
    }

    if (item['2d'] != ""){
        $('.jigsawimage').attr('src',item['2d'])
        $('.jigsawimage').removeClass('hidden')
    }
    if (item['3d'] != ""){
        if ($('#d3-view-image').attr('src') != item['3d']) $('#d3-view-image').attr('src',item['3d'])
        $('#d3-view-image').removeClass('hidden')
    }

    $('#qrcode').empty();
    $('#qrcode').qrcode({
        text: QRPREFIX + id,
        height: 2000,
        width: 2000,
        foreground : '#000000',
        background : '#FFFFFF',
        correctLevel : 2
    })
    $('#qrcode').children().addClass('scaleddown')
    $('#qrcode-download').attr('href',$('#qrcode').children()[0].toDataURL('image/jpeg'));
    $('#qrcode-download').attr('download',item.name + '.jpg')   
    $('#qrcode-container').removeClass('hidden') 
    $('#qrcode').prop('projectid', id)

    drawTrip(id);

    theDisplay = item.display;
    interval2D = setInterval(makeJigSaw, 30000)
    makeJigSaw();
}

function addProject(id,project){
    //console.log('addProject',id,project)
    projects.set(id,project)

    $('#projects-view-list').append('<div id="project-view-entry' + id + '" class="project-view-entry"></div>');{
        $('#project-view-entry' + id).append('<img id="project-view-entry-logo' + id + '" class="project-view-entry-logo" src="' + project.logo + '"></img>')
        $('#project-view-entry' + id).append('<div id="project-view-entry-name' + id + '" class="project-view-entry-name">' + project.name + '</div>')

        $('#project-view-entry' + id).on('click', function(){
            if ($(this).hasClass('project-view-entry-selected')) return;
            $('.project-view-entry').removeClass('project-view-entry-selected')
            $(this).addClass('project-view-entry-selected')
            showProject(id)
        })
    }
}
function modifyProject(id,project){
    //('modifyProject',id,project)
    projects.set(id,project)
    $('#project-view-entry-logo' + id).attr('src', project.logo)
    $('#project-view-entry-name' + id).html(project.name)
    showProject(id)
}
function removeProject(id){
    //console.log('removeProject',id)
    if ($('#project-view-entry' + id).hasClass('project-view-entry-selected'))
        emptyproject();
    projects.delete(id);
    $('#project-view-entry' + id).remove();
}

var drake1 = dragula([document.getElementById('info-view-display-container')], {
    isContainer: function (el) {
        return false;
    },
    moves: function (el, source, handle, sibling) {
        return el.classList.contains('dragable');
    },
    accepts: function (el, target, source, sibling) {
        return true;
    },
    invalid: function (el, handle) {
        return false; // don't prevent any drags from initiating by default
    },
    direction: 'horizontal',             // X axis is considered when determining where an element would be dropped
    copy: false,                       // elements are moved by default, not copied
    revertOnSpill: false,              // spilling will put the element back where it was dragged from, if this is true
    removeOnSpill: false,              // spilling will `.remove` the element, if this is true
    ignoreInputTextSelection: true,     // allows users to select input text, see details below
    slideFactorX: 0,               // allows users to select the amount of movement on the X axis before it is considered a drag instead of a click
    slideFactorY: 0,               // allows users to select the amount of movement on the Y axis before it is considered a drag instead of a click
}).on('over', function (el, container) {
}).on('out', function (el, container) {
}).on('drop', function (el,dst, src, before){
    let arr = []
    $('#info-view-display-container').find('.info-view-display-container-jigsaw-container').each(function(){
        arr.push($(this).data('jigsaw'));
    });
    const id = $('#info-view-container').prop('projectid');
    updateDoc(doc(db,'projects',id),'display',arr)
    .catch(error => {
        console.error(error)
        alert('Could not change display order')
    })
}).on('cloned', function (clone, original, type){
}).on('dragend', function (el, source){
});
