console.log('loading maps.js')

import { Loader } from 'google-maps';
var MarkerClusterer = require('node-js-marker-clusterer');
import 'iconify-icon';


import './map.css';
import posts from './images/greenflag.png';
import { addDoc, collection, deleteDoc, doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { db } from './account';


const options = {
    libraries: ['visualization']        
};
var google = null;
var map = null;
var zones = [];
var mc = null;
var infowindow;
var clicked;
var projectid;
var addButton;
const iconsize = 32;
var geocoder;
var firstview;


const contentString =
    '<div id="content">' +
        '<iconify-icon id="marker-delete" class="marker-delete" icon="fluent:delete-20-regular"></iconify-icon>' +
    "</div>";



export function initMap(container){
    console.log('init')
    var loader = new Loader(process.env.GOOGLEMAPAPIKEY, options);

    loader.load()
    .then(ld => {
        console.log('loaded')
        google = ld;
        map = new google.maps.Map(document.getElementById(container), { 
            "center": { "lat": 0, "lng": 0 }, //EPOS HQ 55.72903819345544, 12.380304641269317
            "fullscreenControl": true, 
            "mapTypeControl": true, 
            "streetViewControl": true, 
            "zoom": 1, 
            "zoomControl": true, 
            "maxZoom": 20, 
            "locationControl": true ,
            "keyboardShortcuts": false,
            "scaleControl": true,
            "styles": [
                {
                  "featureType": "poi",
                  "stylers": [{ "visibility": "off" }],
                },
            ],

        });

        geocoder = new google.maps.Geocoder();
        
        map.setClickableIcons(false);
        mc = new MarkerClusterer(map, [], {imagePath:'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});

        infowindow = new google.maps.InfoWindow({
            content: contentString,
            ariaLabel: "Uluru",
        });

        google.maps.event.addListener(infowindow, 'domready', function(){
            //code to dynamically load new content to infowindow
            //for example:
            //    var existing_content = referenceToInfoWindow.getContent();
            //    var new_content = "...";
            //    referenceToInfoWindow.setContent(existing_content + new_content);

            console.log('info window dom ready')
            $('#marker-delete').on('click', function(){
                console.log('delete marker: ' + clicked)
                deleteDoc(doc(db,'projects', projectid,'points', clicked))
                .catch(error => {
                    console.error(error)
                    alert('Could not delete post')
                })
            })

        }); 

        addButton = document.createElement("button");
        addButton.setAttribute('id','map-add-button')
        addButton.setAttribute("title", 'Add Post');
        addButton.disabled = true;
        addButton.classList.add("map-button");
        addButton.classList.add("map-button-right");
        addButton.classList.add("map-button-top");
        addButton.innerHTML = '<iconify-icon icon="raphael:jigsaw"></iconify-icon>';
        addButton.classList.add("custom-map-control-button");
        map.controls[google.maps.ControlPosition.RIGHT_TOP].push(addButton);
        
        addButton.addEventListener("click", evt => {

            const center = map.getCenter();
            console.log('add button', center)

            addDoc(collection(db, 'projects', projectid, 'points'),{
                position:{
                    latitude: center.lat(),
                    longitude: center.lng()
                }
            })
            .catch(error => {
                console.error(error);
                alert('Could not add post')
            })
        });


    })
    .catch(error => {
        console.error('ERROR loading map: ' + error);
    })
}

function cleanMap(){
    for (let i=0;i < zones.length;i++){
        mc.removeMarker(zones[i]);
        zones[i].setMap(null);
    }
    zones = [];
}

var pointsubscription = null;
export function drawTrip(project=""){
    if (pointsubscription != null) pointsubscription();
    pointsubscription = null;

    if (map == null) return;
    projectid = project;

    if (projectid=="") addButton.disabled = true
    else addButton.disabled = false

    cleanMap();
    firstview = true;

    if (projectid != "") pointsubscription = onSnapshot(collection(db, 'projects', projectid, 'points'), snapshot => doPoints(snapshot))
}

function doPoints(snapshot){
    const post = {
        url: posts,
        size: new google.maps.Size(iconsize, iconsize),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(parseInt(iconsize/2),iconsize),
    };

    cleanMap();

    if (snapshot.size == 0) {
        map.setCenter({"lat": 0, "lng": 0})
        map.setZoom(1)
        return;
    }

    snapshot.forEach(point => {
        const item = point.data();
        const id = point.id

        const marker = new google.maps.Marker({
            position: {
                lat: item.position.latitude,
                lng: item.position.longitude,
            },
            map: map,
            icon: post,
            zIndex: 3,
            optimized: true,
            opacity: 0.9,
            draggable:true,
            ref: id,
        })

        zones.push(marker);
        mc.addMarker(marker)

        marker.addListener("click", () => {
            clicked = id;
            console.log(clicked)
            infowindow.open({
                anchor: marker,
                map,
            });
        });

        google.maps.event.addListener(marker, 'dragend', function() {
            geocoder.geocode({'latLng': marker.getPosition()}, function(results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    if (results[0]) {
                        const docData = {
                            position: {
                                latitude:marker.getPosition().lat(),
                                longitude:marker.getPosition().lng(),
                            }
                        };
                        updateDoc(doc(db,'projects',projectid,'points',id), docData)
                        .catch(error => {
                            console.error(error)
                            alert('Could not move post')
                        })                    
                    }
                }
            });
        })
    })

    if (firstview){
        firstview = false;
        var bounds = new google.maps.LatLngBounds();
        for (let i = 0; i < zones.length; i++)
            bounds.extend(zones[i].getPosition());
        map.fitBounds(bounds);    
    }
}