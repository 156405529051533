console.log('account.js loaded')

import '@fortawesome/fontawesome-free/css/all.css'

import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

import { getAuth, getDoc, OAuthProvider, signInWithPopup, sendPasswordResetEmail, EmailAuthProvider, GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, fetchSignInMethodsForEmail, linkWithCredential, getAdditionalUserInfo   } from "firebase/auth";
import { getFirestore,doc,arrayUnion,setDoc, initializeFirestore, persistentLocalCache, persistentMultipleTabManager} from "firebase/firestore"; 

const firebaseConfig = {
    apiKey: process.env.FIREBASEAPIKEY,
    authDomain: process.env.FIREBASEAUTHDOMAIN,
    projectId: process.env.FIREBASEPROJECTID,
    storageBucket: process.env.FIREBASESTORAGEBUCKET,
    messagingSenderId: process.env.FIREBASEMESSAGESENDERIF,
    appId: process.env.FIREBASEAPPID
};

export const app = initializeApp(firebaseConfig);
const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(process.env.RECAPCHAKEY),
    isTokenAutoRefreshEnabled: true
});

export const auth = getAuth(app);

export const db = initializeFirestore(app, {
    localCache: persistentLocalCache({tabManager: persistentMultipleTabManager()})
});

function getCredential(error){
    switch (error.customData._tokenResponse.providerId){
        case "google.com":
            return GoogleAuthProvider.credentialFromError(error);
    }
    return null;
}

import './account.css'

//Account Dialog for signup/signin
function createAcccountDialog(){
    $('body').append('<div class="modal" id="account"></div>');{
        $('#account').append('<div class="modal-dialog modal-dialog-centered dialogs-dialog" id="accounttop"></div>');
        $('#accounttop').append('<div class="modal-content dialogs-content" id="accountcontent"></div>')

        $('#accountcontent').append('<div class="modal-header centered-header dialogs-header" id="accountheader"></div>')
        $('#accountcontent').append('<div class="modal-body" id="accountbody"></div>')

        $('#accountheader').append('<div class="modal-title  dialogs-title" id="account-title"></div>')
        $('#accountheader').append('<div id="accountclosebutton"><i class="fa fa-times"></i></div>');

        $('#accountbody').append('<button id="googlebutton" type="button" class="btn tiptool"><div id="googletitleblock"><i class="fab fa-google"></i>Use Google</div></button>')

        $('#accountbody').append('<button id="mailbutton" type="button" class="btn tiptool"><div id="mailtitleblock"><i class="fas fa-envelope"></i>Use Email</div></button>')


        $('#accountclosebutton').click(function(event) {
            $('#account').modal('hide');
        });
        $('#accountCancelbutton').on('click', function(event) {
            $('#account').modal('hide');
        });
        $('#accountOKbutton').on('click', function(event) {
            $('#account').modal('hide');
        });
        $('#account').on('shown.bs.modal', function(){
            let mode = $('#account').prop('mode');
            if (mode == 'signup'){
                $('#account-title').html('<span class="mdi mdi-login"></span> Create new Account');
            }else if (mode == 'signin'){
                $('#account-title').html('<span class="mdi mdi-login"></span> Login to your Account');
            }else{
                console.warning('Invoking account dialog with unknown mode')
            }
        })
        $('#account').on('hidden.bs.modal', function(){
            $('#account').remove();
        })
        function login(provider){
            const mode = $('#account').prop('mode');
            $('#account').modal('hide');

            signInWithPopup(auth, provider)
            .then((result) => {
                //console.log('LOGIN OK',provider,result)
                const details = getAdditionalUserInfo(result)
            }).catch((error) => {
                if (error.code === 'auth/account-exists-with-different-credential') {
                    const email = error.customData.email;
                    const credential = getCredential(error);

                    confirmMerge(email,credential);               
                }else{
                    console.error('Login error', error.message)
                    alert( error.message)
                }
            });    
        }

        $('#mailbutton').click(function(){
            //console.log('mailbutton', $('#account').prop('mode'))
            DoMailPassword($('#account').prop('mode'),"",null)
            $('#account').modal('hide');
        })
        $('#googlebutton').click(function(){
            let provider = new GoogleAuthProvider();
            provider.addScope('profile');
            provider.addScope('email');
            login(provider);
        })
    }
}

var linkcredential;
function createMailPasswordDialog(){
    $('body').append('<div class="modal" id="mailpassword"></div>');{
        $('#mailpassword').append('<div class="modal-dialog modal-dialog-centered dialogs-dialog" id="mailpasswordtop"></div>');
        $('#mailpasswordtop').append('<div class="modal-content dialogs-content " id="mailpasswordcontent"></div>')

        $('#mailpasswordcontent').append('<div class="modal-header centered-header dialogs-header" id="mailpasswordheader"></div>')
        $('#mailpasswordcontent').append('<form id="mailpasswordbody" class="modal-body dialogs-body row g-2 mailpassword-page needs-validation" novalidate></form>')
        $('#mailpasswordcontent').append('<div class="modal-footer dialogs-footer" id="mailpasswordfooter"></div>')

        $('#mailpasswordheader').append('<div id="mailpasswordtitle" class="modal-title dialogs-title"><span class="mdi mdi-shield-account-outline dialogs-icon"></span>Provide email and password</div>')
        $('#mailpasswordheader').append('<div id="mailpasswordclosebutton" tabindex="-1"><i class="fa fa-times" ></i></div>');

        $('#mailpasswordbody').append('<div id="mailpassword-row1" class="row g-2"></row>');{
            $('#mailpassword-row1').append('<div id= "mailpassword-row1-col1" class="form-floating col-md-12"></div>')
                $('#mailpassword-row1-col1').append('<input type="email" class="form-control" id="mailpassword-email" required tabindex="1">')
                $('#mailpassword-row1-col1').append('<label for="mailpassword-email">Email</label>');
                $('#mailpassword-row1-col1').append('<div class="invalid-feedback">Illegal Email</div>')
        }

        $('#mailpasswordbody').append('<div id="mailpassword-row2" class="row g-2"></row>');{
            $('#mailpassword-row2').append('<div id= "mailpassword-row2-col1" class="form-floating col-md-12"></div>')
                $('#mailpassword-row2-col1').append('<input type="password"  minlength="6" required class="form-control" id="mailpassword-password" tabindex="2">')
                $('#mailpassword-row2-col1').append('<label for="mailpassword-homepage">Password</label>');
                $('#mailpassword-row2-col1').append('<div class="invalid-feedback">Illegal Password</div>')
        }

        $('#mailpasswordbody').append('<button type="button" id="mailpassword-forgotpassword" class="hidden" tabindex="-1">Illegal Password</button>');

        $('#mailpasswordfooter').append('<button type="button" id="mailpasswordCancelbutton" class="btn dialogs-footerbutton" tabindex="4">Cancel</button>')
        $('#mailpasswordfooter').append('<button type="button" id="mailpasswordOKbutton" class="btn dialogs-footerbutton dialogs-footerbutton-leftmost" tabindex="3">Ok</button>')

        $('#mailpasswordclosebutton').click(function(event) {
            $('#mailpassword').modal('hide');
        });
        $('#mailpasswordCancelbutton').on('click', function(event) {
            $('#mailpassword').modal('hide');
        });

        $('#mailpassword-forgotpassword').click(evt => {
            let email = $('#mailpassword-email').val();
            //console.log(email)
            if (document.getElementById('mailpassword-email').checkValidity() && (email != "")){
                sendPasswordResetEmail(auth, email)
                .then(() => {
                    alert('Password reset email has been sent to you')
                })
                .catch((error) => {
                    const errorMessage = error.message;
                    alert('Password reset mail could not be sent (' + errorMessage + ')')
                });
                
                
            }else{
                //console.log('email not good')
                alert('Input your email in email text field')
            }

            evt.preventDefault();
            evt.stopPropagation();

        })

        

        $('#mailpasswordOKbutton').on('click', function(event) {
            if (!document.getElementById('mailpasswordbody').checkValidity() ){
                event.preventDefault()
                event.stopPropagation()
                $('#mailpasswordbody').addClass('was-validated')
                return;
            }

            const mode = $('#mailpassword').prop('mode')
            const email = $('#mailpassword-email').val()
            const password = $('#mailpassword-password').val()

            $('#mailpassword').modal('hide');

            

            if (mode == 'signup'){
                createUserWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    const user = userCredential.user;
                })
                .catch((error) => {
                    console.error('sign up failed',error.code)
                    if (error.code === 'auth/email-already-in-use') {
                        signInWithEmailAndPassword(auth, email, password)
                        .then((userCredential) => {
                        })
                        .catch((error) => {
                            const credential = EmailAuthProvider.credential(email, password);    
                            confirmMerge(email,credential);               
                        });
                    }else{
                        alert(error.message)
                    }
                });
            
            }else if (mode == 'signin'){
                signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    // Signed in 
                    const user = userCredential.user;
                    if (linkcredential != null)
                        return linkWithCredential(user,linkcredential);
                })
                .then(function(){
                    //console.log('linked')
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;

                    //console.log(error,errorCode,errorMessage)

                    if (errorCode == 'auth/user-not-found') alert('Email is invalid')
                    else if (errorCode === 'auth/wrong-password') alert('Invalid password')
                    else alert('Could not login, try again<br>' + errorMessage)

                    $('#mailpassword').modal('show');
                });
            }else
                console.warning('Using mailpassword dialog in illegal mode: ' + mode)

        });
        $('#mailpassword').on('shown.bs.modal', async function(){
            $('#mailpasswordbody').removeClass('was-validated')
            $('#mailpassword-email').focus();
            const mode = $('#mailpassword').prop('mode')
            //console.log('show',mode)
            if (mode=='signin') $('#mailpassword-forgotpassword').removeClass('hidden')

        })
        $('#mailpassword').on('hidden.bs.modal', async function(){
            $('#mailpassword').remove();
        })

        $('#mailpassword').on('keypress',function(evt){
            //console.log('keypress')
            if ((evt.keycode == 13 || evt.which == 13)){
                console.log('enter')
                $('#mailpasswordOKbutton').trigger('click')
            }
        })
    }
}
function DoMailPassword(mode,email,credential=null){
    //console.log('DoMailPassword',mode)
    linkcredential = credential;
    createMailPasswordDialog();
    $('#mailpassword').prop('mode',mode);
    
    $('#mailpassword-email').val(email)
    $('#mailpassword-password').val("")

    $('#mailpassword-email').attr('readonly',email != "")
    
    $('#mailpassword').modal('show')
}

var theemail;
var thecredential;
function createConfirmMergeDialog(){
    $('body').append('<div class="modal" id="confirmMergeDialog"></div>');{
        $('#confirmMergeDialog').append('<div class="modal-dialog modal-dialog-centered" id="confirmMergeDialogtop"></div>');
        $('#confirmMergeDialogtop').append('<div class="modal-content dialogs-content" id="confirmMergeDialogcontent"></div>')

        $('#confirmMergeDialogcontent').append('<div class="modal-header centered-header dialogs-header" id="confirmMergeDialogheader"></div>')
        $('#confirmMergeDialogcontent').append('<form id="confirmMergeDialogbody" class="modal-body dialogs-body row g-2 confirmMergeDialog-page"></form>')
        $('#confirmMergeDialogcontent').append('<div class="modal-footer dialogs-footer" id="confirmMergeDialogfooter"></div>')

        $('#confirmMergeDialogheader').append('<div class="modal-title dialogs-title" id="confirmMergeDialogtitle"><span class="mdi mdi-check dialogs-icon"></span>An account with this email already exists</div>')
        $('#confirmMergeDialogheader').append('<div class="dialogs-closeicon" id="confirmMergeDialogclosebutton"><span class="mdi mdi-window-close"></span></div>');

        $('#confirmMergeDialogbody').append('<div id="confirmMergeDialog-row2" class="row g-2"></row>');{
            $('#confirmMergeDialog-row2').append('<div id= "confirmMergeDialog-row1-col1" class="form-floating col-md-12">Would you like to use both for login?</div>')
        }

        $('#confirmMergeDialogfooter').append('<button type="button" id="confirmMergeDialogCancelbutton" class="btn dialogs-footerbutton">Cancel</button>')
        $('#confirmMergeDialogfooter').append('<button type="button" id="confirmMergeDialogOKbutton" class="btn dialogs-footerbutton dialogs-footerbutton-leftmost">Ok</button>')

        $('#confirmMergeDialogclosebutton').click(function(event) {
            $('#confirmMergeDialog').modal('hide');
        });
        $('#confirmMergeDialogCancelbutton').on('click', function(event) {
            $('#confirmMergeDialog').modal('hide');
        });

        function getProviderForProviderId(id){
            //console.log(id)
            switch (id){
                case "twitter.com":
                    return new TwitterAuthProvider();
                case "google.com":
                    return new GoogleAuthProvider();
                case "microsoft.com":
                    return new OAuthProvider('microsoft.com')
                case "apple.com":
                    return new OAuthProvider('apple.com')
                case "facebook.com":
                    return new FacebookAuthProvider();
            }
            return null;
        }

        $('#confirmMergeDialogOKbutton').on('click', function(event) {
            $('#confirmMergeDialog').modal('hide');

            fetchSignInMethodsForEmail(auth, theemail)
            .then(function(methods) {

                if (methods[0] === 'password') {
                    DoMailPassword('signin',theemail,thecredential);
                }else{
                    var provider = getProviderForProviderId(methods[0]);
                    
                    signInWithPopup(auth, provider)
                    .then(function(result) {
                        return linkWithCredential(result.user, thecredential)
                    })
                    .then(function(usercred) {
                        //console.log('linked')
                    })
                }
            })
        })

        $('#confirmMergeDialog').on('hidden.bs.modal', async function(){
            $('#confirmMergeDialog').remove();
        })
    }
}
function confirmMerge(email, credential)
{
    theemail = email;
    thecredential = credential
    createConfirmMergeDialog();
    $('#confirmMergeDialog').modal('show')
}

export function doSignIn(){
    createAcccountDialog();
    $('#account').prop('mode','signin')
    $('#account').modal('show');
}
export function doSignUp(){
    createAcccountDialog();
    $('#account').prop('mode','signup')
    $('#account').modal('show');    
}
