// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gutter {
    background-color: #eee;

    background-repeat: no-repeat;
    background-position: 50%;
}

.gutter.gutter-horizontal {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    cursor: col-resize;
}

.gutter.gutter-vertical {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    cursor: row-resize;
}

.split {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.split, .gutter.gutter-horizontal {
    float: left;
}

.split, .gutter.gutter-horizontal {
    height: 100%;
}
.split {
    overflow-y: auto;
    overflow-x: hidden;
}`, "",{"version":3,"sources":["webpack://./src/split.js.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;;IAEtB,4BAA4B;IAC5B,wBAAwB;AAC5B;;AAEA;IACI,yDAA2K;IAC3K,kBAAkB;AACtB;;AAEA;IACI,yDAA2L;IAC3L,kBAAkB;AACtB;;AAEA;IACI,8BAA8B;IAC9B,2BAA2B;IAC3B,sBAAsB;AAC1B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;AACA;IACI,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".gutter {\r\n    background-color: #eee;\r\n\r\n    background-repeat: no-repeat;\r\n    background-position: 50%;\r\n}\r\n\r\n.gutter.gutter-horizontal {\r\n    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');\r\n    cursor: col-resize;\r\n}\r\n\r\n.gutter.gutter-vertical {\r\n    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=');\r\n    cursor: row-resize;\r\n}\r\n\r\n.split {\r\n    -webkit-box-sizing: border-box;\r\n    -moz-box-sizing: border-box;\r\n    box-sizing: border-box;\r\n}\r\n\r\n.split, .gutter.gutter-horizontal {\r\n    float: left;\r\n}\r\n\r\n.split, .gutter.gutter-horizontal {\r\n    height: 100%;\r\n}\r\n.split {\r\n    overflow-y: auto;\r\n    overflow-x: hidden;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
